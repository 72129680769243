// TODO: cleanup unused methods
import StoryblokClient, { StoryblokResult } from 'storyblok-js-client';
import { StoryDataFromGraphQLQuery } from '../../templates/types';
import { getGlobalConfigElement } from '../../utils/get-global-config-element';
import { BuildService } from '../build';
import { StoryblokService } from '../storyblok';
import { NavigationService } from '../navigation';

export const LanguageService = {
  getDefaultLocale: (): string => process.env.GATSBY_STORYBLOK_SPACE_DEFAULT_LANGUAGE,
  storyblokClient: new StoryblokClient({
    accessToken: StoryblokService.getConfig().options.accessToken as string,
  }),
  async getSpaceLanguages(): Promise<string[]> {
    this.storyblokClient.setCacheVersion(Date.now());
    const allLinks = await this.storyblokClient.getAll('cdn/links', { version: StoryblokService.getConfig().options.headers.Version });
    return [
      ...new Set(allLinks.map(({ slug }) => this.getLocaleFromSlug(slug))),
    ] as string[];
  },
  getLocaleFromSlug(slug: string): string {
    // Matches: /en/*, /en-GB/*, /en-US/*
    const languageCodeMatches = (
      `/${slug}`
        .replace('//', '/')
        .match(/^\/([\w]{2})-([\w]{2})\/|^\/([\w]{2})\//) ?? []
    )
      .map((match) => match?.split('/').join(''));

    return languageCodeMatches[0] || this.getDefaultLocale();
  },
  /**
   * NOTE:
   * When invoking in a server side rendering context, please pass locale as an argument!
   * This prevent a fatal exceptions, as `document` isn't defined in this context.
   */
  isPageDefaultLanguage(locale?: string): boolean {
    if (!locale && typeof document === 'undefined') {
      return true;
    }
    return this.getDefaultLocale() === (locale || document.documentElement.lang);
  },
  getActiveLanguage(): string {
    if (typeof document === 'undefined') {
      return this.getDefaultLocale();
    }

    const actualPagePath = NavigationService.getCurrentPagePath();

    return LanguageService.getLocaleFromSlug(actualPagePath);
  },
  getParsedLocale(locale: string): string {
    return locale === 'default' ? LanguageService.getDefaultLocale() : locale;
  },
  getPathTokens(path: string, separator = '/'): string[] {
    return path
      ? path.split(separator)
      : [];
  },

  /**
   * Remove the leading slash in a path if there is one.
   */
  removeLeadingSlash(path: string): string {
    return path.startsWith('/') ? path.substring(1) : path;
  },

  /**
   * Prefix a path with the correct language code.
   *
   * The exact language code resolution is quite intricate and depends on the
   * chosen option for "Localised routes" (cf. README.md) with which the
   * Storyblok space is configured.
   *
   * Examples:
   *
   * If the Storyblok space uses multiple languages:
   *
   * (A1) The input `path` may start with or without a leading slash
   *
   * getLocaleAwareLink('some/path/', 'fr') === '/fr/some/path'
   * getLocaleAwareLink('/some/path/', 'fr') === '/fr/some/path'
   *
   * (A2) An existing locale is replaced
   *
   * getLocaleAwareLink('/fr/some/path/', 'pt') === '/pt/some/path'
   *
   * (A3) If the given `locale` is the default language, it will not be included in the path
   *
   * getLocaleAwareLink('/fr/some/path/', 'en') === '/some/path'
   *
   * If the Storyblok space uses only the default language:
   *
   * (B1) The locale is included and not replaced for routes that are NOT client-only routes
   *
   * getLocaleAwareLink('/fr/some/path/', 'en') === '/fr/some/path'
   *
   * (B2) For routes that ARE client-only, the language code resolution works
   * according to (A1) - (A3)
   *
   * getLocaleAwareLink('/fr/media/releases/<...>', 'en') === '/media/releases/<...>'
   */
  getLocaleAwareLink(path: string, locale: string): string {
    if (!path) {
      return '';
    }

    const pathTokens = this.getPathTokens(this.removeLeadingSlash(path));

    const [firstPathElement, ...rest] = pathTokens;

    if (this.isLocale(firstPathElement)
      && this.isSpaceSingleLanguage()
      && !BuildService.isClientOnlyPath(path)) {
      return `/${pathTokens.join('/')}`;
    }

    const pathWithoutLocale = this.isLocale(firstPathElement) ? rest : pathTokens;
    const localizedPath = this.isPageDefaultLanguage(locale)
      ? pathWithoutLocale
      : [locale, ...pathWithoutLocale];

    return `/${localizedPath.join('/')}`;
  },

  /**
   * Check if a string is a valid locale.
   *
   * TODO: Check what kind of standard we are using (POSIX, ISO 639-1, etc.) and improve
   * the regex accordingly or even better use a library for this.
   */
  isLocale(locale: string): boolean {
    const localeRegex = /^[A-Za-z]{2}$/;

    return localeRegex.test(locale);
  },

  parseTranslationDatasource(sources: Record<string, string>[]): Record<string, string> {
    return sources.reduce((accumulator, source) => {
      if (!source) {
        return accumulator;
      }

      const {
        dimension_value: translatedValue,
        name,
        value,
      } = source;

      return {
        ...accumulator,
        [name]: translatedValue || value,
      };
    }, {});
  },

  parseTranslationDatasourceWithDefaultNameAsKey(
    sources: Record<string, string>[],
  ): Record<string, string> {
    return sources.reduce((accumulator, source) => {
      if (!source) {
        return accumulator;
      }

      const {
        dimension_value: translatedValue,
        name,
        value,
      } = source;

      if (name === value) {
        return {
          ...accumulator,
          [name]: translatedValue || value,
        };
      }

      return {
        ...accumulator,
        [name]: translatedValue || value,
        [value]: translatedValue || value,
      };
    }, {});
  },

  async getDatasourceWithDimensionValuesFromLocales(
    locales: string[],
    datasourceName: string,
  ): Promise<StoryblokResult[][]> {
    const datasourceDimensions = await StoryblokService.getDatasourceDimensionNames(
      this.storyblokClient,
      datasourceName,
    );

    const localeDimensionNamesToFetch = locales.map((locale) => {
      const localeDimension = datasourceDimensions.find(
        (dimension) => dimension.entry_value?.toLocaleLowerCase() === locale.toLocaleLowerCase(),
      );
      return localeDimension ? localeDimension.entry_value : locale;
    });

    return Promise.all([
      ...localeDimensionNamesToFetch.map((locale: string) => StoryblokService.getDatasources(
        this.storyblokClient,
        [datasourceName],
        { dimension: locale, cv: Date.now() },
      )),
    ]);
  },

  async fetchTranslations(datasourceName: string): Promise<Record<string, Record<string, string>>> {
    this.storyblokClient.setCacheVersion(Date.now());
    const locales = await this.getSpaceLanguages();
    const datasources = await this.getDatasourceWithDimensionValuesFromLocales(
      locales,
      datasourceName,
    );

    const translations = locales
      .reduce(
        (accumulator, value, i) => (
          value
            ? {
              ...accumulator,
              [value]: this.parseTranslationDatasource(datasources[i][0].data.datasource_entries),
            }
            : accumulator
        ),
        {},
      );

    return translations;
  },

  async fetchTranslationsWithDefaultValueAsKey(
    datasourceName: string,
  ): Promise<Record<string, Record<string, string>>> {
    this.storyblokClient.setCacheVersion(Date.now());
    const locales = await this.getSpaceLanguages();
    const datasources = await this.getDatasourceWithDimensionValuesFromLocales(
      locales,
      datasourceName,
    );

    const translations = locales
      .reduce(
        (accumulator, value, i) => (
          value
            ? {
              ...accumulator,
              [value]: this.parseTranslationDatasourceWithDefaultNameAsKey(
                datasources[i][0].data.datasource_entries,
              ),
            }
            : accumulator
        ),
        {},
      );

    return translations;
  },

  /**
   * Media and investor releases have links pointing to versions in other languages.
   * These links are localized which means that we need access to multiple languages
   * at the same time. For the moment, these translations are hardcoded.
   *
   * TODO: Find a better solution.
   */
  readThisArticleIn: {
    en: 'Read this article in English',
    de: 'Lesen Sie diesen Artikel auf Deutsch',
    fr: 'Lire cet article en Français',
    es: 'Lea este artículo en Español',
    cn: '点击查看中文版',
  },

  /**
   * In some cases (e.g. for external data) it is necessary to use languages
   * that might not have been added to the Storyblok space configuration.
   * (Side note: The languages that ARE configured in Storyblok can be retrieved
   * with the `getLanguages` function).
   */
  getAdditionalLanguages(): Array<string> {
    return Object.keys(this.readThisArticleIn);
  },

  isSpaceSingleLanguage(): boolean {
    if (typeof document === 'undefined') {
      return true;
    }

    const globalConfigElement = getGlobalConfigElement();
    const languages = globalConfigElement?.dataset.languages || '[]';
    return JSON.parse(languages).length <= 1;
  },

  getHomePageUrl(homeStory: StoryDataFromGraphQLQuery): string {
    const fallbackPath = '/';

    if (!homeStory) {
      return fallbackPath;
    }

    const { full_slug: slug } = homeStory;
    const lang = LanguageService.getLocaleFromSlug(slug);
    const path = lang === LanguageService.getDefaultLocale() ? fallbackPath : slug;

    return LanguageService.getLocaleAwareLink(path, LanguageService.getParsedLocale(lang));
  },

  async getAllLocals(storyblokClient: StoryblokClient) {
    try {
      const datasourceResponses = await StoryblokService.getDatasources(storyblokClient, ['space-languages']);

      if (datasourceResponses && datasourceResponses.length > 0) {
        const locales = datasourceResponses
          .flatMap(({ data }) => data.datasource_entries)
          .map((entry) => entry.name);

        return locales.length > 0 ? locales : await LanguageService.getSpaceLanguages();
      }
      const allSpaceLocales = await LanguageService.getSpaceLanguages();
      return allSpaceLocales;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching locales:', error);
      const allSpaceLocales = await LanguageService.getSpaceLanguages();
      return allSpaceLocales;
    }
  },
};
